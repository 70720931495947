import {
    createBrowserRouter,
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import CategoryPage from "../pages/CategoryPage";
import SubCategoryPage from "../pages/SubCategoryPage";
import HomePage from "../pages/HomePage";
import ProductListPage from "../pages/ProductListPage";
import ProductDetailsPage from "../pages/ProductDetailsPage";
import { getPendingOrders, getProductDetailsLoader } from "./loaders";
import CartPage from '../pages/CartPage'
import RootPage from "../pages/RootPage";
import ShareCataloguePage from "../pages/ShareCataloguePage";
import SearchPage from "../pages/SearchPage";
import AboutUsPage from "../pages/AboutUsPage";
import OffersPage from "../pages/OffersPage";
import TermsPage from "../pages/TermsPage";
import ErrorPage from "../pages/ErrorPage";
import { PendingOrderPage } from "../pages/PendingOrderPage";
import PaymentSuccessPage from "../pages/PaymentSuccessPage";
import PaymentFailPage from "../pages/PaymentFailPage";
import { PaymentsPage } from "../pages/PaymentsPage";
import RefundPolicyPage from "../pages/RefundPolicyPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsConditionsPage from "../pages/TermsConditionsPage";
export const router = createHashRouter([
    {

        element: <RootPage />,
        children: [{
            path: '/',
            element: <HomePage />,
            errorElement: <ErrorPage />
        },
        {
            path: "Categories",
            element: <CategoryPage />,
            errorElement: <ErrorPage />
        },
        {
            path: "SubCategories",
            element: <SubCategoryPage />,
            errorElement: <ErrorPage />
        },
        {
            path: "Products",
            element: <ProductListPage />,
            errorElement: <ErrorPage />
        },
        {
            path: "Productdetails/:product_id",
            element: <ProductDetailsPage />,
            loader: getProductDetailsLoader,
            errorElement: <ErrorPage />
        },
        {
            path: "Cart",
            element: <CartPage />,
            errorElement: <ErrorPage />
        },
        {
            path: "Sharecatalogue",
            element: <ShareCataloguePage />,
            errorElement: <ErrorPage />
        },
        {
            path: "Search/:search_name",
            element: <SearchPage />,
            errorElement: <ErrorPage />
        },
        // {
        //     path: "About",
        //     element: <AboutUsPage />,
        //     errorElement: <ErrorPage />
        // },
        {
            path: "Offers",
            element: <OffersPage />,
            errorElement: <ErrorPage />
        },
        {
            path: "Terms&Conditions",
            element: <TermsPage />,
            errorElement: <ErrorPage />

        },
        {
            path: "PendingOrders",
            element: <PendingOrderPage />,
            loader: getPendingOrders,
            errorElement: <ErrorPage />
        },
        {
            path:"RefundPolicy",
            element:<RefundPolicyPage/>,
            errorElement: <ErrorPage />
        },
        {
            path:"PrivacyPolicy",
            element:<PrivacyPolicyPage/>,
            errorElement: <ErrorPage />
        },
        {
            path:"TermsAndConditions",
            element:<TermsConditionsPage/>,
            errorElement: <ErrorPage />
        },
        ],
        errorElement: <ErrorPage />
    },

    {
        path: "PaymentSuccess/:order_id",
        element: <PaymentSuccessPage />,
        errorElement: <ErrorPage />
    },
    {
        path: "PaymentFail/:order_id",
        element: <PaymentFailPage />,
        errorElement: <ErrorPage />
    },
    {
        path: "PaymentsPage/:order_id/:amount/:name/:number/:token/:branch_id",
        element: <PaymentsPage />,
        errorElement: <ErrorPage />
    }
])

