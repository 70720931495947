import { useEffect, useState } from 'react';
import * as language from '../constants/languages';
import SpinnerLoader from '../components/SpinnerLoader';
import HeaderCommon from '../components/HeaderCommon';
import { useNavigate } from 'react-router-dom';
import Trash from '../assets/svg/delete.svg'
import Plus from '../assets/svg/plus.svg'
import Minus from '../assets/svg/minus.svg'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/CartPage.css'
import { Snackbar } from '@mui/material';
export default function CartPage() {
    const navigate = useNavigate()
    let mediaQueryList = window.matchMedia("(min-width: 992px)");

    const branchId = localStorage.getItem('storeId');

    const [isLoading, setisLoading] = useState(true)
    const [cartItems, setcartItems] = useState([])
    const [totalValue, settotalValue] = useState(0)
    const [decimalPlaces, setdecimalPlaces] = useState(2)
    const [outOfStock, setoutOfStock] = useState(false)
    const [currencySymbol, setcurrencySymbol] = useState()
    const [itemCount, setitemCount] = useState()
    const [snackopen, setsnackopen] = useState(false)
    const [message, setmessage] = useState('')
    const [isLargeDisplay, setisLargeDisplay] = useState(mediaQueryList.matches)

    useEffect(() => {

        window.addEventListener('cartitemremoved', getCartItemsFn)
        // checkLogin();
        setisLoading(true)
        getCompanyInfo();
        getCartItemsFn();
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", checkDevice)
        }
        else {
            mediaQueryList.addListener(checkDevice)
        }
        return () => {
            if (mediaQueryList.addEventListener) {
                mediaQueryList.removeEventListener("change", checkDevice)
            } else {
                mediaQueryList.removeListener(checkDevice)
            }
            window.removeEventListener('cartitemremoved', getCartItemsFn);
        }
    }, [])

    //check if mobile
    function checkDevice(mql) {
        setisLargeDisplay(mql.matches)
    }

    //get sotre name
    async function getCompanyInfo() {
        const value = localStorage.getItem('companyInfo')

        if (value === null || value === undefined) {
            setcurrencySymbol("")
        } else {
            const companyInfo = JSON.parse(value);
            setcurrencySymbol(companyInfo.currencySymbol)
            setdecimalPlaces(companyInfo.decimalValue)
        }

    }

    // get cart items
    async function getCartItemsFn() {
        let cartitems
        let totalvalue = 0;
        let cart = await localStorage.getItem('cartItems');
        if (!cart) {
            let items = {
                customerCartItems: [],
                branchId: parseInt(branchId),
                companyId: language.companyidValue
            }
            setcartItems(items)
            setisLoading(false)

        } else {

            cartitems = JSON.parse(cart);
            setcartItems(cartitems)
            setisLoading(false)
            setitemCount(cartitems.customerCartItems.length)
        }
        if (!cart) {
            return
        }

        for (let i = 0; i < cartitems?.customerCartItems?.length; i++) {

            if (cartitems.customerCartItems[i].product.oRate != null) {
                if (cartitems.customerCartItems[i].product.incrementValue == null) {
                    totalvalue = (totalvalue + (cartitems.customerCartItems[i].qty * cartitems.customerCartItems[i].product.oRate))

                } else {
                    totalvalue = (totalvalue + ((cartitems.customerCartItems[i].qty * cartitems.customerCartItems[i].product.oRate)))

                }
            } else {
                if (cartitems.customerCartItems[i].product.incrementValue == null) {
                    totalvalue = (totalvalue + (cartitems.customerCartItems[i].qty * cartitems.customerCartItems[i].product.sRate))

                } else {
                    totalvalue = (totalvalue + ((cartitems.customerCartItems[i].qty * cartitems.customerCartItems[i].product.sRate)))

                }
            }
            if (outOfStock == false) {
                if (cartitems.customerCartItems[i].product.isStockAvailable == false) {
                    //out of stock items
                    setoutOfStock(true)

                } else {

                    setoutOfStock(false)

                }
            }
        }
        settotalValue(totalvalue)


    }

    async function reCalculateCartTotal(cart) {

        let total = 0;
        setoutOfStock(false)

        // let cart = storage.getString('cartItems');
        if (!cart) { return }
        // let cartItems = JSON.parse(cart);
        let cartItems = cart;

        for (let i = 0; i < cartItems?.customerCartItems?.length; i++) {

            if (cartItems.customerCartItems[i].product.oRate != null) {
                if (cartItems.customerCartItems[i].product.incrementValue == null) {
                    total = total + (cartItems.customerCartItems[i].qty * cartItems.customerCartItems[i].product.oRate)

                } else {
                    total = total + (cartItems.customerCartItems[i].qty * cartItems.customerCartItems[i].product.oRate)
                }
            } else {
                if (cartItems.customerCartItems[i].product.incrementValue == null) {
                    total = total + (cartItems.customerCartItems[i].qty * cartItems.customerCartItems[i].product.sRate)

                } else {
                    total = total + (cartItems.customerCartItems[i].qty * cartItems.customerCartItems[i].product.sRate)
                }
            }
            if (outOfStock == false) {
                if (cartItems.customerCartItems[i].product.isStockAvailable == false) {
                    //out of stock items
                    setoutOfStock(true)


                } else {
                    setoutOfStock(false)

                }
            }

        }
        settotalValue(total)

    }

    // quantity select
    function quantityPressed(itemIndex, valueAction, productPrice, productId, cartItem, e) {
        e.stopPropagation()
        let incrementValue
        if (cartItem.product.incrementValue == null) {
            incrementValue = 1
        } else {
            incrementValue = cartItem.product.incrementValue
        }
        // if (['value_' + itemIndex] == null) {
        if (valueAction === 'increment') {

            cartAddRemove(productId, 1, cartItem.product.categoryId, cartItem.product.brandId, cartItem, itemIndex) //add qny to cart

        } else {
            // if (['quantity_' + itemIndex] != incrementValue) {
            if (cartItem.qty != incrementValue) {
                cartAddRemove(productId, 0, cartItem.product.categoryId, cartItem.product.brandId, cartItem, itemIndex)
            } //reduce qny to cart
            // }
            else {
                removeItem(cartItem.productId, cartItem, e)
            }
        }
        // }


    }

    async function cartAddRemove(id, action, cateId, brandId, cartItem, itemIndex) {

        let incrementValue = 0
        if (cartItem.product.incrementValue == null) {
            incrementValue = 1
        } else {
            incrementValue = cartItem.product.incrementValue
        }
        if (action == 1) { //add to cart

            if (cartItem.product.isStockAvailable == true) {

                let cartItemsJson = localStorage.getItem('cartItems');
                let cartitems
                if (cartItemsJson == null) {
                    cartitems = {
                        customerCartItems: [
                        ],
                        branchId: parseInt(branchId),
                        companyId: language.companyidValue
                    }
                } else {
                    cartitems = JSON.parse(cartItemsJson);
                }


                const elementsIndex = cartitems.customerCartItems.findIndex(element => element.product.productId == cartItem.product.productId)
                if (branchId == cartitems.branchId) {
                    var requiredQty = elementsIndex == -1 ? incrementValue : (cartitems.customerCartItems[elementsIndex].qty + incrementValue);
                    if (cartItem.product.allowNegativeStock == true || requiredQty <= (cartItem.product.stock ?? 0)) {
                        if (elementsIndex == -1) {
                            cartitems.customerCartItems = [
                                ...cartitems.customerCartItems,
                                {
                                    product: cartItem.product,
                                    qty: incrementValue,
                                    variantId: null
                                }
                            ]
                        } else {
                            cartitems.customerCartItems[elementsIndex] = {
                                product: cartitems.customerCartItems[elementsIndex].product,
                                qty: cartitems.customerCartItems[elementsIndex].qty + incrementValue,
                                variantId: cartitems.customerCartItems[elementsIndex].variantId
                            }
                        }
                        localStorage.setItem('cartItems', JSON.stringify(cartitems))

                        reCalculateCartTotal(cartitems);
                        setcartItems(cartitems)
                        const event = new CustomEvent('productQtyChange');
                        window.dispatchEvent(event)

                        // showToastMessage(language.itemAddedTocart);
                    } else {
                        showToastMessage(language.cartAddRemove);
                    }


                } else {

                    showToastMessage(language.anotherBranch);
                }

            } else {
                showToastMessage(language.cartAddRemove);
            }


        } else { //reduce from cart

            let cartItemsJson = localStorage.getItem('cartItems');
            let cartitems = {}
            if (!cartItemsJson) {
                cartitems = {
                    customerCartItems: [
                    ],
                    branchId: parseInt(branchId),
                    companyId: language.companyidValue
                }
            } else {
                cartitems = JSON.parse(cartItemsJson);
            }

            if (branchId == cartitems.branchId) {

                const elementsIndex = cartitems.customerCartItems.findIndex(element => element.product.productId == cartItem.product.productId);
                if (elementsIndex > -1) {
                    var requiredQty = elementsIndex == -1 ? incrementValue : (cartitems.customerCartItems[elementsIndex].qty - incrementValue);
                    if (requiredQty > 0) {
                        cartitems.customerCartItems[elementsIndex] = {
                            product: cartitems.customerCartItems[elementsIndex].product,
                            qty: (requiredQty < 0 ? 0 : requiredQty),
                            variantId: cartitems.customerCartItems[elementsIndex].variantId,
                        }
                        localStorage.setItem('cartItems', JSON.stringify(cartitems));

                        reCalculateCartTotal(cartitems);
                        setcartItems(cartitems)
                        const event = new CustomEvent('productQtyChange');
                        window.dispatchEvent(event)

                        // showToastMessage(language.itemReducedFromCart);
                    } else {


                    }
                } else {


                }

            } else {

                showToastMessage(language.anotherBranch);
            }

        }
    }



    // remove item from cart
    async function removeItem(id, cartItem, e) {
        e.stopPropagation()
        const cart = localStorage.getItem('cartItems')
        const cartitems = JSON.parse(cart);
        const elementsIndex = cartitems.customerCartItems.findIndex(element => element.product.productId == cartItem.product.productId);
        cartitems.customerCartItems.splice(elementsIndex, 1);
        if (cartitems?.customerCartItems?.length == 0) {
            localStorage.removeItem('cartItems')
        }
        else {
            localStorage.setItem('cartItems', JSON.stringify(cartitems));
        }
        setitemCount(cartitems?.customerCartItems?.length)
        reCalculateCartTotal(cartitems);
        setcartItems(cartitems)
        const event = new CustomEvent('productQtyChange');
        window.dispatchEvent(event)

        showToastMessage(language.removedFromCart);

    }

    const showToastMessage = (message) => {
        setmessage(message)
        setsnackopen(true)
    }
    const onSnackClose = () => {
        setsnackopen(false)

    }

    const action = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setsnackopen(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (

        <div className='cart-page-container'>
            <HeaderCommon />
            {
                isLoading == true ?
                    <SpinnerLoader />
                    :
                    isLargeDisplay ?
                        cartItems?.customerCartItems?.length == 0 ?
                            <div style={{ flex: 1, alignItems: 'center', height: '10rem', display: 'flex', justifyContent: 'center' }}>
                                <p>{language.noItmsLabe}</p>
                            </div>
                            :
                            <table >
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th >Description</th>
                                        <th >Quantity</th>
                                        <th >Total</th>
                                        <th >Remove</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cartItems.customerCartItems.map((cartItem, index) => (

                                            <tr style={{ borderBottomWidth: index == cartItems?.customerCartItems?.length - 1 ? 0 : 0.4, borderColor: '#70707030', borderBottomStyle: 'solid', cursor: 'pointer' }} key={index} onClick={() => navigate(`/Productdetails/${cartItem.product.productId}`)}>
                                                <td style={{ paddingBlock: '2rem' }}>
                                                    <div>
                                                        <img src={cartItem.product.tblProductImages ? cartItem.product.tblProductImages[0].image : null} width={130} height={100} />
                                                    </div>
                                                </td>
                                                <td >

                                                    <div >
                                                        <p style={{ fontFamily: 'Poppins-Regular', color: '#9E9E9E', fontSize: 9 }}>{cartItem.product.brand?.brandName}</p>
                                                        <p style={{ fontFamily: 'Poppins-Regular' }}>{cartItem.product?.productName}</p>
                                                        <p style={{ fontFamily: 'Poppins-Regular', fontSize: 12, }}>{cartItem.product.regionalLanguage == null ? "" : cartItem.product.regionalLanguage}</p>

                                                        {
                                                            cartItem.product.isStockAvailable == false ?
                                                                <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.red, marginInline: 0 }}>{language.outStockLabel}</p>
                                                                :
                                                                <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.green, margin: 0, }}>{language.inStockLabel}</p>
                                                        }
                                                    </div>

                                                </td>
                                                <td  >

                                                    {
                                                        cartItem.product.isStockAvailable == false ?
                                                            null
                                                            :

                                                            <div className='cart-large-quantitywrapper'>

                                                                {/* <div onClick={(e) => quantityPressed((index + 1), 'decrement', cartItem.product.sRate, cartItem.productId, cartItem, e)} >
                                                                    <img src={Minus} size={14} />

                                                                </div> */}

                                                                <div style={{ marginHorizontal: 10, alignItems: 'center', justifyContent: 'center', alignSelf: 'center', fontSize: 12, textAlign:"center" }}>

                                                                    {
                                                                        cartItem.product.unit != '-- None --' ?
                                                                            <p style={{ fontFamily: 'Poppins-Regular', margin: 0, textAlign:"center" }}>{cartItem.qty} {cartItem.product.unit}</p>
                                                                            :
                                                                            <p style={{ fontFamily: 'Poppins-Regular', margin: 0, textAlign:"center" }}>{cartItem.qty}</p>
                                                                    }
                                                                </div>

                                                                {/* <div onClick={(e) => quantityPressed((index + 1), 'increment', cartItem.product.sRate, cartItem.productId, cartItem, e)} >
                                                                    <img src={Plus} size={14} />

                                                                </div> */}
                                                            </div>

                                                    }
                                                </td>

                                                <td>
                                                    {

                                                        cartItem.product.oRate == null ?
                                                            <div style={{ alignItems: 'center', }}>
                                                                {
                                                                    cartItem.product.sRate < (cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) ?
                                                                        <div style={{}}>
                                                                            <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', marginRight: 5, fontSize: 12, marginBottom: 0 }}>
                                                                                {currencySymbol} {(cartItem.product.mrp * cartItem.product.incrementValue).toFixed(2)}
                                                                            </p>
                                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000', marginBottom: 0 }}>
                                                                                {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                                            </p>

                                                                        </div>
                                                                        :

                                                                        <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000', marginBottom: 0 }}>
                                                                            {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                                        </p>
                                                                }
                                                            </div>
                                                            :
                                                            <div style={{ alignItems: 'center' }}>
                                                                {
                                                                    cartItem.product.oRate < (cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) ?
                                                                        <div style={{}}>
                                                                            <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', marginRight: 5, fontSize: 12, marginBottom: 0 }}>
                                                                                {currencySymbol} {((cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) * cartItem.product.incrementValue).toFixed(2)}
                                                                            </p>
                                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000', marginBottom: 0 }}>
                                                                                {currencySymbol} {(cartItem.product.oRate * cartItem.product.incrementValue).toFixed(2)}
                                                                            </p>

                                                                        </div>
                                                                        :
                                                                        <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000', marginBottom: 0 }}>
                                                                            {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                                        </p>

                                                                }
                                                            </div>


                                                    }
                                                </td>
                                                <td>
                                                    <div className='removeButtonWraper' onClick={(e) => removeItem(cartItem.productId, cartItem, e)}>
                                                        <img src={Trash} height={20} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        :
                        <div style={{ flex: 1, marginBottom: 20, display: 'flex', marginInline: '1rem' }}>

                            {
                                cartItems?.customerCartItems?.length == 0 ?
                                    <div style={{ flex: 1, alignItems: 'center', marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                        <p>{language.noItmsLabe}</p>
                                    </div>
                                    :

                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        {
                                            cartItems.customerCartItems.map((cartItem, index) => (

                                                <div key={index} style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, paddingBottom: '3rem', marginBottom: 10, borderBottomWidth: index == cartItems?.customerCartItems?.length - 1 ? 0 : 0.4, borderColor: '#707070', alignItems: 'center', borderBottomStyle: 'solid', position: 'relative' }} onClick={() => navigate(`/Productdetails/${cartItem.product.productId}`)}>
                                                    <div style={{ display: 'flex', flex: 1, }}>
                                                        <img src={cartItem.product.tblProductImages ? cartItem.product.tblProductImages[0].image : null} width={130} height={100} style={{ objectFit: 'cover' }} />
                                                    </div>
                                                    <div style={{ marginLeft: 15, display: 'flex', flex: 2, flexDirection: 'column', }}>

                                                        <p style={{ fontFamily: 'Poppins-Regular', color: '#9E9E9E', fontSize: 9 }}>{cartItem.product.brand?.brandName}</p>
                                                        <p style={{ fontFamily: 'Poppins-Regular', marginBottom: 0 }}>{cartItem.product?.productName}</p>
                                                        <p style={{ fontFamily: 'Poppins-Regular', fontSize: 12, }}>{cartItem.product.regionalLanguage == null ? "" : cartItem.product.regionalLanguage}</p>

                                                        {
                                                            cartItem.product.isStockAvailable == false ?
                                                                <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.red, marginBottom: 5 }}>{language.outStockLabel}</p>
                                                                :
                                                                <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.green, marginBottom: 5 }}>{language.inStockLabel}</p>
                                                        }
                                                        {

                                                            cartItem.product.oRate == null ?
                                                                <div style={{ alignItems: 'center', }}>
                                                                    {
                                                                        cartItem.product.sRate < (cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) ?
                                                                            <div style={{}}>
                                                                                <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', marginRight: 5, fontSize: 12, marginBottom: 0 }}>
                                                                                    {currencySymbol} {(cartItem.product.mrp * cartItem.product.incrementValue).toFixed(2)}
                                                                                </p>
                                                                                <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                                                    {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                                                </p>

                                                                            </div>
                                                                            :

                                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                                                {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                                            </p>
                                                                    }
                                                                </div>
                                                                :
                                                                <div style={{ alignItems: 'center' }}>
                                                                    {
                                                                        cartItem.product.oRate < (cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) ?
                                                                            <div style={{}}>
                                                                                <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', marginRight: 5, fontSize: 12, marginBottom: 0 }}>
                                                                                    {currencySymbol} {((cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) * cartItem.product.incrementValue).toFixed(2)}
                                                                                </p>
                                                                                <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                                                    {currencySymbol} {(cartItem.product.oRate * cartItem.product.incrementValue).toFixed(2)}
                                                                                </p>

                                                                            </div>
                                                                            :
                                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                                                {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                                            </p>

                                                                    }
                                                                </div>


                                                        }

                                                    </div>
                                                    <div style={{ display: 'flex', alignSelf: 'flex-start', marginTop: 15 }}>
                                                        <div className='removeButtonWraper' onClick={(e) => removeItem(cartItem.productId, cartItem, e)}>
                                                            <img src={Trash} height={20} />
                                                        </div>
                                                    </div>
                                                    <div style={{ alignSelf: 'flex-end', position: 'absolute', right: 15, bottom: 20 }}>
                                                        <div style={{}}>
                                                            {
                                                                cartItem.product.isStockAvailable == false ?
                                                                    null
                                                                    :

                                                                    <div className='quantityWrapper'>

                                                                        {/* <div onClick={(e) => quantityPressed((index + 1), 'decrement', cartItem.product.sRate, cartItem.productId, cartItem, e)} >
                                                                            <img src={Minus} size={14} />

                                                                        </div> */}

                                                                        <div style={{ marginHorizontal: 10, alignItems: 'center', justifyContent: 'center', alignSelf: 'center', fontSize: 12, flex:1, }}>

                                                                            {
                                                                                cartItem.product.unit != '-- None --' ?
                                                                                    <p style={{ fontFamily: 'Poppins-Regular', margin: 0 , textAlign:"center"}}>{cartItem.qty} {cartItem.product.unit}</p>
                                                                                    :
                                                                                    <p style={{ fontFamily: 'Poppins-Regular', margin: 0,textAlign:"center" }}>{cartItem.qty}</p>
                                                                            }
                                                                        </div>

                                                                        {/* <div onClick={(e) => quantityPressed((index + 1), 'increment', cartItem.product.sRate, cartItem.productId, cartItem, e)} >
                                                                            <img src={Plus} size={14} />

                                                                        </div> */}
                                                                    </div>

                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                            ))
                                        }
                                    </div>

                            }

                        </div>
            }
            {
                totalValue > 0 ?
                    <div className='cart-page-total'>
                        <p style={{ fontFamily: 'Poppins-Regular', fontSize: 18 }}>Total</p>

                        <p style={{ fontFamily: 'Montserrat-Medium', color: '#FF0000', fontSize: 18 }}>{currencySymbol} {totalValue}</p>
                    </div>
                    : null
            }
            {
                (isLargeDisplay && cartItems?.customerCartItems?.length > 0) ?
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }} onClick={() => navigate('/Sharecatalogue')}>
                        <p className='cart-page-large-order-button'>
                            Place Order
                        </p>
                    </div>
                    : null

            }
            <Snackbar
                open={snackopen}
                autoHideDuration={2000}
                onClose={onSnackClose}
                message={message}
                action={action}
            />
        </div >
    )
}



