import { useEffect, useState } from "react";

//libraries
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { Snackbar } from "@mui/material";

//api controllers
import { getOrderWithOrderId, getSupportData, sendWhatsapp } from "../services/services";

//icons
import TabCategory from '../assets/svg/bottom tab bar/tabcategory.svg'
import VerifiedIcon from '../assets/svg/verified.svg'
import DownloadIcon from "../assets/svg/download.svg"

//constants
import * as language from '../constants/languages'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function PaymentSuccessPage() {
    const { order_id } = useParams()
    const navigate = useNavigate()
    const currencySymbol = ''

    const [orderPlacedData, setOrderPlacedData] = useState();
    const [whatsInstanceid, setwhatsInstanceid] = useState()
    const [whatsApptoken, setwhatsApptoken] = useState()
    const [snackMessage, setSnackMessage] = useState('');
    const [isSnackopen, setisSnackopen] = useState(false)
    const [whatsAppNo, setwhatsAppNo] = useState()
    const [decryptedId, setDecryptedId] = useState()
    const [encryptedQr, setEncryptedQr] = useState()
    function clearCart() {
        const event = new CustomEvent('catalogueShare');
        const productEvent = new CustomEvent('productQtyChange');
        localStorage.removeItem('cartItems')
        window.dispatchEvent(event)
        window.dispatchEvent(productEvent)
        sendToWhatspp()
    }

    const decryptMessage = async () => {
        const key = new TextEncoder().encode('ulccsitinfrastructureprivateltd.');
    
        // Reverse URL-safe Base64 encoding
        let base64String = order_id.replace(/-/g, '+').replace(/_/g, '/');
        const mod4 = base64String.length % 4;
        if (mod4 > 0) {
            base64String += '===='.slice(mod4);
        }
    
        const encryptedBytes = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
        const iv = encryptedBytes.slice(0, 16);
        const data = encryptedBytes.slice(16);
    
        try {
            const cryptoKey = await window.crypto.subtle.importKey(
                'raw',
                key,
                {
                    name: 'AES-CBC'
                },
                false, // key cannot be extracted using SubtleCrypto.exportKey() / SubtleCrypto.wrapKey()
                ['decrypt'] // keyUsages
            );
    
            const plaintext = await window.crypto.subtle.decrypt(
                {
                    name: "AES-CBC",
                    iv: iv
                },
                cryptoKey,
                data
            );
    
            const decryptedText = new TextDecoder().decode(plaintext);
            setDecryptedId(decryptedText); // Assuming setDecryptedId is a function to set the decrypted value
        } catch (error) {
            console.error('Decryption failed', error);
        }
    }


    async function getSupportInfoData() {
        try {
            const supportInfoResponse = await getSupportData(language.companyidValue)
            if (supportInfoResponse?.status === 'success') {
                setwhatsAppNo(supportInfoResponse?.data?.whatsApp)
                setwhatsInstanceid(supportInfoResponse?.data?.whatsAppInstanceID)
                setwhatsApptoken(supportInfoResponse?.data?.whatsAppInstanceID)
            }
        } catch (error) {

        }
    }

    async function sendToWhatspp() {
        try {
            const getCart = localStorage.getItem('cartItems')
            const cart = JSON.parse(getCart);
            const sortedCartItems = []
            for (let i = 0; i < cart?.customerCartItems?.length; i++) {
                sortedCartItems[i] = `\n${i + 1}. \*${cart?.customerCartItems[i]?.product?.productName}\*   *   ${cart?.customerCartItems[i]?.qty} ${cart?.customerCartItems[i]?.product?.unit == '-- None --' ? "" : cart?.customerCartItems[i]?.product?.unit}`
            }
            const msg = encodeURIComponent(`\*Your Order Recieved\*  \n\n\*Order No:\* ${orderPlacedData?.orderNo ? orderPlacedData?.orderNo : ''} \n\n \*Product Details:\* \n${sortedCartItems.join('\n')}\n\n\*Total:\*   ${currencySymbol ? currencySymbol : ''} ${orderPlacedData ? orderPlacedData.totalAmount : ''}\n\n\*Customer Details:\*\n\n${orderPlacedData ? orderPlacedData.customerName : ''}\n${orderPlacedData?.orderAddresses[0] ? orderPlacedData.orderAddresses[0].houseNo : ''}\n\n\*Contact Number:\* ${orderPlacedData.orderAddresses[0].mobileNo1 ? orderPlacedData.orderAddresses[0].mobileNo1 : ''}\n\n\*Land Mark\*: ${orderPlacedData.orderAddresses[0].landmark ? orderPlacedData.orderAddresses[0].landmark : ''} `)
            const mobileNumber = orderPlacedData?.orderAddresses[0]?.mobileNo1
            await sendWhatsapp(mobileNumber, msg, whatsInstanceid, whatsApptoken).then((response) => {
                // console.log(response)
            }).catch((error) => {
                setSnackMessage('Whatsapp message was not delivered')
                setisSnackopen(true)
            })
        } catch (error) {

        }
    }

    const generateQrImage = () => {
        const myDiv = document.getElementById('qrDiv');
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (myDiv) {
            html2canvas(myDiv).then((canvasData) => {
                const img = new Image();
                img.src = canvasData.toDataURL('image/png');
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);

                    // Convert canvas content to a data URL
                    const pngFile = canvas.toDataURL('image/png');

                    // Create a download link
                    const downloadLink = document.createElement('a');
                    downloadLink.download = 'OrderReceipt.png';
                    downloadLink.href = pngFile;

                    // Trigger a click event on the download link to prompt the user to download the image
                    downloadLink.click();
                };
            });
        } else {
            console.error("Div element with ID 'myDiv' not found");
        }
    };

    async function getOrder() {
        try {
            const orderDetailResponse = await getOrderWithOrderId(language.companyidValue, decryptedId)
            if (orderDetailResponse.status === "success") {
                setOrderPlacedData(orderDetailResponse.data[0])
                setEncryptedQr(orderDetailResponse.message)
            }

        } catch (error) {
            console.log(error)
        }
        clearCart()
    }

    const onSnackClose = () => {
        setisSnackopen(false)

    }

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setisSnackopen(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    useEffect(() => {
        getSupportInfoData()
        decryptMessage()
    }, [])

    useEffect(() => {
        if(decryptedId){
            getOrder() 
        }
       
    }, [decryptedId])



    return (
        orderPlacedData &&

        <div style={{ backgroundColor: 'white', padding: 30, display: 'block', alignItems: 'center', flexDirection: 'column', borderRadius: 3, justifyContent: 'center', overflow: 'auto', flex: 1 }} >
            <span style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <img src={VerifiedIcon} />
            </span>
            <h1 style={{ textAlign: 'center', fontFamily: 'Poppins-Regular', fontWeight: 'bold' }}>Your order was placed successfully</h1>
            <div id="qrDiv" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingInline: 30, paddingBlock: 15, width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: 15 }}>
                    <QRCode
                        size={128}
                        value={String(encryptedQr)}
                        style={{ height: 'auto',color:"#000000" }}
                    />
                </div>
                <div style={{ borderTop: 'dashed', marginTop: 30, paddingBlock: 15, borderTopWidth: 1 }}>
                    <span >
                        <h5>Order details</h5>
                        <div style={{ marginInline: 15 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: '500', flex: 1 }}>Order No :</div>
                                <div style={{ fontWeight: '500', }}>{orderPlacedData?.orderNo}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: '500', flex: 1 }}>Order Date : </div>
                                <div style={{ fontWeight: '500', }}>{orderPlacedData?.orderDate ? new Date(orderPlacedData.orderDate).toLocaleDateString("en-GB") : ''}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: '500', flex: 1 }}>Customer name :</div>
                                <div style={{ fontWeight: '500', }}>{orderPlacedData?.customerName}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: '500', flex: 1 }}>Mobile number :</div>
                                <div style={{ fontWeight: '500', }}>{orderPlacedData?.orderAddresses[0] ? orderPlacedData?.orderAddresses[0].mobileNo1 : ''}</div>
                            </div>
                        </div>
                    </span>
                    <div style={{ marginBlock: 15, display: 'flex', flex: 1 }}>
                        <table style={{ width: '100%' }}>
                            <thead style={{ borderBlock: 'dashed', borderBlockWidth: 1 }}>
                                <tr >
                                    <th style={{ textAlign: 'center', paddingBlock: 8 }}>Items</th>
                                    <th style={{ textAlign: 'center', padding: 8 }}>Quantity</th>
                                    <th style={{ textAlign: 'center', padding: 8 }}>Total</th>
                                </tr>
                            </thead>
                            <tbody style={{ paddingBlock: 15 }}>
                                {orderPlacedData?.orderItems?.map((item, index) =>
                                    <tr style={{}} key={index} >
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ flex: 1, marginRight: 10 }}>{item.name}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div>x{item.quantity} </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div>{currencySymbol} {item.subTotal}</div>
                                        </td>
                                    </tr>
                                )}
                                <tr style={{ borderTop: 'dashed', borderTopWidth: 1, borderSpacing: `0 1rem` }}>
                                    <td style={{ textAlign: 'center' }}>
                                        <div style={{ fontWeight: 'bold' }}>Subtotal</div>
                                    </td>
                                    <td></td>
                                    <td style={{ textAlign: 'center' }}>
                                        <div style={{ fontWeight: 'bold', }}>{currencySymbol} {orderPlacedData?.totalAmount}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>


            </div>
            <div style={{ fontWeight: '500', color: '#00000075', fontFamily: 'Poppins-Regular', textAlign: 'center' }}>
                Thank you so much for your order
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="download-button" onClick={generateQrImage}>
                    <img src={DownloadIcon} style={{ marginRight: 15 }} />
                    Download Qr
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 15, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#1d4e4b', padding: 10, borderRadius: 3, color: '#fff', display: 'flex', margin: 5, cursor: 'pointer', minWidth: '20%', }} onClick={() => navigate('/', { replace: true })}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.223" height="21.225" viewBox="0 0 19.223 21.225" stroke='#fff'>
                        <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3.9 -2.4)">
                            <path id="Path_114" data-name="Path 114" d="M4.5,10.009,13.511,3l9.011,7.009V21.023a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2Z" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                            <path id="Path_115" data-name="Path 115" d="M13.5,28.013V18h6.008V28.013" transform="translate(-2.992 -4.987)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                        </g>
                    </svg>
                    <p style={{ marginBlock: 0, marginLeft: 15 }}>Home</p>
                </div>
                <div style={{ backgroundColor: '#1d4e4b', padding: 10, borderRadius: 3, color: '#fff', display: 'flex', margin: 5, cursor: 'pointer', minWidth: '20%' }} onClick={() => navigate('/Categories', { replace: true })}>
                    <img src={TabCategory} />
                    <p style={{ marginBlock: 0, marginLeft: 15 }}>Category</p>
                </div>
            </div>
            <Snackbar
                open={isSnackopen}
                autoHideDuration={2000}
                onClose={onSnackClose}
                message={snackMessage}
                action={action}
            />
        </div>
    )

}