import { useEffect, useState } from "react"
import { getCcAvenueEncryptedString, getPaymentProviders, paymentFailOrderCancel } from "../services/services"
import * as language from '../constants/languages'
import { useNavigate, useParams } from "react-router-dom"
import { Spinner } from 'react-bootstrap';
export function PaymentsPage() {
    const { order_id, amount, name, number, token, branch_id } = useParams()
    const navigate = useNavigate()
    const [encryptedMessage, setencryptedMessage] = useState('')

    // async function getPaymentProvidersDetails() {
    //     // setisLoading(true)
    //     try {
    //         const getPaymentProvidersResponse = await getPaymentProviders(language.companyidValue)
    //         if (getPaymentProvidersResponse.status === "success") {
    //             // if (getPaymentProvidersResponse.data.length === 1) {
    //             //     setPaymentGateway(getPaymentProvidersResponse.data[0])
    //             // }
    //             // else {
    //             //     setPaymentProviders(getPaymentProvidersResponse.data)
    //             // }
    //             console.log(getPaymentProvidersResponse)
    //         }
    //     } catch (error) {

    //     }
    //     // setisLoading(false)
    // }

    async function cancelOrderOnError() {
        try {
            await paymentFailOrderCancel(language.companyidValue, branch_id, order_id, token)
        } catch (error) {
            console.log(error, 'err')
        }
        navigate(`/PaymentFail/${order_id}`)
    }

    async function startPayment() {

        const paymentDetails = {
            "order_id": order_id,
            "amount": Number(amount)?.toFixed(2),
            "currency": "INR",
            "redirect_url": `${language.domainUrl}api/ccAvenue/Callback`,
            "cancel_url": `${language.domainUrl}api/ccAvenue/Callback`,
            "billing_name": name,
            "billing_address": "",
            "billing_city": "",
            "billing_state": "",
            "billing_zip": "",
            "billing_country": "",
            "billing_tel": number,
            "billing_email": "",
            "delivery_name": name,
            "delivery_address": "",
            "delivery_city": "",
            "delivery_state": "",
            "delivery_zip": "",
            "delivery_country": "",
            "delivery_tel": number,
            "merchant_param1": "additional Info.",
            "merchant_param2": "additional Info.",
            "merchant_param3": "additional Info.",
            "merchant_param4": "additional Info.",
            "merchant_param5": "additional Info.",
            "promo_code": "",
            "customer_identifier": ""
        }
        try {
            const encryptionResponse = await getCcAvenueEncryptedString(paymentDetails, token)
            if (encryptionResponse?.message) {
                setencryptedMessage(encryptionResponse?.message)
            } else {
                cancelOrderOnError()
            }
        } catch (error) {
            cancelOrderOnError()
        }
    }

    useEffect(() => {
        try {
            if (encryptedMessage.length > 1) {
                const form = document.getElementById("nonseamless")
                form.submit()
            }
        } catch (error) {
            cancelOrderOnError()
        }

    }, [encryptedMessage])

    useEffect(() => {
        // getPaymentProvidersDetails()
        startPayment()
    }, [])
    return (
        <>
            <form id="nonseamless" method="POST" name="redirect" action="https://secure.ccavenue.com/transaction.do?command=initiateTransaction">
                <input type="hidden" id="encRequest" name="encRequest" value={encryptedMessage} />
                <input type="hidden" name="access_code" id="Hidden1" value="AVJG52LA46CA71GJAC" />
            </form>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spinner animation="border" role="status" />
            </div>
        </>

    )
}

