import QRCode from "react-qr-code";

import html2canvas from "html2canvas";

import TabCategory from '../assets/svg/bottom tab bar/tabcategory.svg'
import VerifiedIcon from '../assets/svg/failed.svg'
import DownloadIcon from "../assets/svg/download.svg"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOrderDetails, getOrderWithOrderId } from "../services/services";
import * as language from '../constants/languages'

export default function PaymentFailPage() {
    const { order_id } = useParams()
    const navigate = useNavigate()
    const currencySymbol = ''
    // const [orderPlacedData, setOrderPlacedData] = useState({})
    // const orderDate = new Date("2023-12-05T10:06:17.3213225+05:30").toLocaleDateString()

    // const generateQrImage = () => {
    //     const myDiv = document.getElementById('qrDiv');
    //     const canvas = document.createElement('canvas');
    //     const ctx = canvas.getContext('2d');

    //     if (myDiv) {
    //         html2canvas(myDiv).then((canvasData) => {
    //             const img = new Image();
    //             img.src = canvasData.toDataURL('image/png');
    //             img.onload = () => {
    //                 canvas.width = img.width;
    //                 canvas.height = img.height;
    //                 ctx.drawImage(img, 0, 0);

    //                 // Convert canvas content to a data URL
    //                 const pngFile = canvas.toDataURL('image/png');

    //                 // Create a download link
    //                 const downloadLink = document.createElement('a');
    //                 downloadLink.download = 'OrderReceipt.png';
    //                 downloadLink.href = pngFile;

    //                 // Trigger a click event on the download link to prompt the user to download the image
    //                 downloadLink.click();
    //             };
    //         });
    //     } else {
    //         console.error("Div element with ID 'myDiv' not found");
    //     }
    // };


    // async function getOrder() {
    //     try {
    //         const orderDetailResponse = await getOrderWithOrderId(language.companyidValue, order_id)
    //         if(orderDetailResponse.status === "success"){
    //             setOrderPlacedData(orderDetailResponse.data[0])
    //         }
    //         console.log(orderDetailResponse, 'orderDetailResponse')
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }


    return (
        <div style={{ backgroundColor: 'white', padding: 30, display: 'flex', alignItems: 'center', flexDirection: 'column', borderRadius: 3, justifyContent: 'center', overflow: 'auto', flex: 1,  width: '100vw', height: '100vh' }} >
            <span style={{ display: 'flex', padding: 30, justifyContent: 'center' }}>
                <img src={VerifiedIcon} />
            </span>
            <h1 style={{ textAlign: 'center', fontFamily: 'Poppins-Regular', fontWeight: 'bold' }}>Order placement failed</h1>

            <div style={{ fontWeight: '500', color: '#00000075', fontFamily: 'Poppins-Regular', textAlign: 'center' }}>
                Please try again later...
            </div>

            <div style={{ display: 'flex', marginTop: 15, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#1d4e4b', padding: 10, borderRadius: 3, color: '#fff', display: 'flex', margin: 5, cursor: 'pointer', minWidth: '20%', }} onClick={() => navigate('/', { replace: true })}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.223" height="21.225" viewBox="0 0 19.223 21.225" stroke='#fff'>
                        <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3.9 -2.4)">
                            <path id="Path_114" data-name="Path 114" d="M4.5,10.009,13.511,3l9.011,7.009V21.023a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2Z" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                            <path id="Path_115" data-name="Path 115" d="M13.5,28.013V18h6.008V28.013" transform="translate(-2.992 -4.987)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                        </g>
                    </svg>
                    <p style={{ marginBlock: 0, marginLeft: 15 }}>Home</p>
                </div>
                <div style={{ backgroundColor: '#1d4e4b', padding: 10, borderRadius: 3, color: '#fff', display: 'flex', margin: 5, cursor: 'pointer', minWidth: '20%' }} onClick={() => navigate('/Categories', { replace: true })}>
                    <img src={TabCategory} />
                    <p style={{ marginBlock: 0, marginLeft: 15 }}>Category</p>
                </div>
            </div>
        </div>
    )

}